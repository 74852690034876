
import { defineComponent, ref, onBeforeMount, computed, nextTick } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import popup from "@/components/modals/wizards/ProductIntegrationModal.vue";
import { getCookie, removeCookie } from "typescript-cookie";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import { useRouter } from "vue-router";
import { ValidationError } from "yup";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { DataUtil } from "../../../assets/ts/_utils/_DataUtil";
import { Product } from "../../../dtos/Product";
import Multiselect from '@vueform/multiselect'
import { ProductNGDTO } from "../../../dtos/ProductNGDTO";
import { ProductStatus } from "../../../dtos/ProductStatus";
import { IntegrationProduct } from "@/dtos/IntegrationProduct";
export default defineComponent({
  name: "productList",
  components: {
    popup,
    Multiselect
  },
  props: {
    PropList: Array,
  },
  data() {
    return {
      SelectedProduct: new ProductNGDTO(),
    };
  },
  setup(props) {
    const SelectedPro = ref();
    const SelectedInd = ref();
    const indexes = ref();
    const store = useStore();
    const List = ref();
    const branch = ref();
    const mergedList = ref();
    const router = useRouter();
    const filterparams = ref("");
    const popupList = ref();
    const closeproducts = ref([]);
    const selectedCategory = ref([]);

    const undocombine = async () => {
      console.log("undo metod");
    };
    const filtervaluem = computed(() => {
      const tempdata = List.value != undefined ? List.value : props.PropList;
      let filteredData = tempdata;

      // Filtreleme parametresi varsa, ürün adına göre filtrele
      if (filterparams.value !== "") {
        filteredData = filteredData.filter((item: any) => {
          return item.name.toLowerCase().includes(filterparams.value.toLowerCase());
        });
      }

      // Seçili kategori varsa, ürünün kategorisine göre filtrele
      if (selectedCategory.value.length > 0) {
        filteredData = filteredData.filter((item: any) => {
          return item.integrationProducts.some(iproduct => {
            const categoryName = iproduct.product?.CategoryName;
            const categoryId = categories.value.indexOf(categoryName);
            return categoryId > -1 ? selectedCategory.value.includes(categoryId as never) : false;
          });
        });
      }

      return filteredData;
    });
    const categories = computed(() => {
      const tempdata = props.PropList as ProductNGDTO[];
      if (tempdata) {
        // gather all unique CategoryNames in all tempdata items all integration products
        const categoryNames = new Set(tempdata.flatMap(item =>
          item.integrationProducts?.flatMap(iproduct =>
            iproduct.product?.CategoryName ? [iproduct.product?.CategoryName] : []
          )
        ));
        return Array.from(categoryNames);
      }
      return [];
    });
    const changeStatusForIProduct = async (
      item: IntegrationProduct,
      status: ProductStatus
    ) => {
      store
        .dispatch(Actions.UPDATE_STATUS, {
          values: {
            product: item,

            status: status,
          },
          headers: {
            token: getCookie("access_token"),
          },
        })
        .then((r) => {
          if (r == 502) {
            store.dispatch(Actions.LOGOUT).then(() => {
              router.push({ name: "sign-in" });
            });
          } else {
            if (r.status == 201 && item.product) {
              item.product.Status = status;
              console.log("başarılı");
            } else if (r == 401) {
              store.dispatch(Actions.LOGOUT).then(() => {
                router.push({ name: "sign-in" });
              });
            }
          }
        })
        .catch((error) => {
          store.dispatch(Actions.LOGOUT).then(() => {
            router.push({ name: "sign-in" });
          });
        });
    };
    const changeStatusForAll = async (
      item: ProductNGDTO,
      status: ProductStatus
    ) => {
      item.integrationProducts?.forEach((p) => {
        if (p.product?.Status != status) changeStatusForIProduct(p, status);
      });
    };
    const deleteitem = async (index) => {
      let temp = Object.assign([], props.PropList);
      popupList.value = temp;
      popupList.value.splice(index, 1);
    };
    const getCloseProducts = async () => {
      store.dispatch(Actions.GETCLOSEPRODUCTS, props.PropList).then((e) => {
        List.value = e;
      });
    };

    const getOpenProducts = async () => {
      store.dispatch(Actions.GETOPENPRODUCTS, props.PropList).then((e) => {
        console.log(e);
        List.value = e;
      });
    };

    return {
      deleteitem,
      closeproducts,
      getCloseProducts,
      getOpenProducts,
      popupList,
      List,
      filtervaluem,
      SelectedPro,
      SelectedInd,
      indexes,
      filterparams,
      changeStatusForIProduct,
      changeStatusForAll,
      mergedList,
      branch,
      reinitializeComponents,
      undocombine,
      ProductNGDTO,
      ProductStatus,
      IntegrationProduct, 
      selectedCategory,
      categories
    };
  },
});
