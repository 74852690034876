import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 gx-xl-8" }
const _hoisted_2 = { class: "col-xxl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableWidget9 = _resolveComponent("TableWidget9")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TableWidget9, {
        PropList: _ctx.data,
        "widget-classes": "card-xxl-stretch mb-5 mb-xl-8",
        onNeedRefresh: _ctx.refreshData
      }, null, 8, ["PropList", "onNeedRefresh"])
    ])
  ]))
}