import { IntegrationProduct } from "./IntegrationProduct";
import { ProductStatus } from "./ProductStatus";

export class ProductNGDTO {
  name?: string;
  integrationProducts?: IntegrationProduct[];
  mergedProduct?: any;
  HideImage = false;
  //Define a getter for the first letter of the name
  static firstLetter(val: ProductNGDTO) {
    return val.name?.charAt(0);
  }
  //Define a getter for priceEqual check in IntegrationProducts
  static priceEqual(val: ProductNGDTO) {
    if (val.integrationProducts && val.integrationProducts.length > 1) {
      const price = val.integrationProducts[0].product?.Price;
      for (let i = 1; i < val.integrationProducts.length; i++) {
        if (val.integrationProducts[i].product?.Price != price) {
          return false;
        }
      }
      return true;
    }
    return true;
  }
  //Define a getter for imageUrl which is the first image which is not null or empty of the integrationProducts
  static imageUrl(val: ProductNGDTO) {
    if (val.integrationProducts) {
      for (let i = 0; i < val.integrationProducts.length; i++) {
        if (
          val.integrationProducts[i].product?.PhotoUrl != null &&
          val.integrationProducts[i].product?.PhotoUrl != ""
        ) {
          return val.integrationProducts[i].product?.PhotoUrl;
        }
      }
    }
    val.HideImage = true;
    return "";
  }

  //Define a getter named isThereAnyOpen which is true if there is any open integrationProduct
  static isThereAnyOpen(val: ProductNGDTO) {
    if (val.integrationProducts) {
      for (let i = 0; i < val.integrationProducts.length; i++) {
        if (val.integrationProducts[i].product?.Status == ProductStatus.Open) {
          return true;
        }
      }
    }
    return false;
  }
}
