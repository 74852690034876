
import { defineComponent, onMounted, onBeforeMount, ref } from "vue";
import TableWidget9 from "@/components/widgets/tables/ProductList.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default defineComponent({
  name: "dashFard",
  components: {
    TableWidget9,
  },
  setup() {
    const data = ref();
    const store = useStore();
    const refreshData =  async () => {
      await store.dispatch(Actions.PRODUCTS, "token").then((response: any) => {
        //console.log(response);
        data.value = response.data.products;
      });
    };
    onBeforeMount(async () => {
      refreshData();
    });

    onMounted(async () => {
      setCurrentPageTitle("Ürün Açma, Kapatma");
    });
    return {
      data,
      refreshData
    };
  },
});
