
import { defineComponent, ref, computed, onBeforeMount,defineEmits } from "vue";
import $ from "jquery";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { getCookie, setCookie } from "typescript-cookie";
import { useRouter } from "vue-router";
import { ProductNGDTO } from "@/dtos/ProductNGDTO";
import { IIntegrationElement } from "@/dtos/IIntegrationElement";
import { IntegrationProduct } from "@/dtos/IntegrationProduct";

export default defineComponent({
  name: "ModalPopUp",
  components: {},

  props: {
    Valuem: Array,
    SelectedProduct: ProductNGDTO,
    Indexes: Number,
  },
  watch: {
    SelectedProduct: function () {
      this.filterparams = "";
    },
  },
  emits: ['saved'],
  data() {
    return {
      checked: false,
    };
  },
  setup(props: any,ctx:any) {
    const store = useStore();
    const mergeddata = ref();
    const router = useRouter();
    const filterparams = ref("");
    const Combine = async (
      currentItem: ProductNGDTO,
      selected: ProductNGDTO | undefined,
      valuem: ProductNGDTO[] | undefined
    ) => {
      const items: any = {
        product: selected,
        token: getCookie("access_token"),
      };
      if(!selected) return;
      selected.integrationProducts = selected.integrationProducts?.concat(
        currentItem.integrationProducts as IntegrationProduct[]
      );
      if(!valuem) return;
      valuem.splice(valuem.indexOf(currentItem), 1);
      mergeddata.value = items;
      var data = valuem;
      localStorage.setItem("mergedProduct", JSON.stringify(data));
    };

    const setdata = async (selected: ProductNGDTO | undefined) => {
      const items: any = {
        product: selected,
        token: getCookie("access_token"),
      };
      mergeddata.value = items;
      store.dispatch(Actions.MERGE_PRODUCTS, mergeddata.value).then((res) => {
        console.log(res);
        if (res.status == 201) {
          setCookie("access_token", res.data.access_token);
          setCookie("refresh_token", res.data.refresh_token);
          $("#CloseMergeModal").click();
          ctx.emit('saved');
        } else {
          store.dispatch(Actions.LOGOUT).then(() => {
            router.push({ name: "sign-in" });
          });
        }
      });
    };
    const removeItem = async (selectedProduct:ProductNGDTO|undefined,itemIndex:number)=>{
      selectedProduct?.integrationProducts?.splice(
                      itemIndex,
                      1
                    );
    }
    const filtervaluem = computed(() => {
      console.log(props.Indexes);
      if (filterparams.value == "") {
        return props.Valuem?.filter((item:any) => {
          return item != props.SelectedProduct;
        });
      } else {
        return props?.Valuem?.filter((item: any) => {
          return item.name
            .toLowerCase()
            .includes(filterparams.value.toLowerCase()) && item != props.SelectedProduct;
        });
      }
    });
    onBeforeMount(() => {
      console.log("onBeforeMount");
    });
    return {
      filterparams,
      filtervaluem,
      mergeddata,
      Combine,
      setdata,
      ProductNGDTO,
      IIntegrationElement,
      removeItem
    };
  },
});
